/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import WufooForm from "react-wufoo-embed"
import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import { ColorBar, HeroGradient } from "../../../components/elements"

const CreateFundraiser = ({ data, pageContext }) => {
  const page = data.datoCmsCreateFundraiser
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        <Section>
          <Container>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h2>{page.introContent}</Styled.h2>
                <br />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />

                <WufooForm
                  userName="wvuhealthcare"
                  formHash="z1c13l2t0t3yl87"
                  header="hide"
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default CreateFundraiser

export const query = graphql`
  {
    datoCmsCreateFundraiser {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      id
      introContent
      slug
      title
      seoMetaTags {
        tags
      }
    }
  }
`
